<template>
  <div>
    <a
      v-if="src"
      :href="bannerUrl"
      class="c-flash-banner__link"
    >
      <picture class="c-flash-banner__link--picture">
        <source 
          v-for="(size, index) in widthSet" 
          :key="size"
          :srcset="getSrcInWidth(webpSrc, size)" 
          :media="`(min-width:${index === 0 ? 1 : (widthSet[index-1] - 100)}px)`"
          type="image/webp"
        >
        <img 
          :srcset="getSrcset(src)"
          :src="src"
          class="c-flash-banner__link--image"
          alt="Main banner image"
          @load="handleBannerLoaded"
        >
      </picture>
    </a>
    <div
      v-else
      class="c-flash-banner__slider-placeholder"
    />
  </div>
</template>
<script>
import urlJoin from 'url-join';
import { FILE_SIZE_SET }  from '../../constants';

export default {
  props: {
    mainBanner: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    widthSet() {
      return FILE_SIZE_SET.slice(0, 1);
    },
    bannerUrl() {
      return urlJoin(this.mainBanner.click_url, '?utm_source=homepage&utm_medium=flash_banner&utm_promo=flash_banner_1')
    },
    src() {
      return this.mainBanner.image || '';
    },
    webpSrc() {
      return `${this.src}.webp`;
    },
  },
  methods: {
    async fetchBanners() {
      this.$store.dispatch('homepage/fetchBanners', { platform: 'mobile_web' });
    },
    getSrcset(image) {
      if (!image) return '';
      const srcset = [];
      FILE_SIZE_SET.forEach(width => {
        srcset.push(`${image.replace(/\/original\//, `/w-${width}/`)} ${width}w`);
      });
      return srcset.join();
    },
    getSrcInWidth(image, width) {
      if (!image) return '';
      return image.replace(/\/original\//, `/w-${width}/`);
    },
    handleBannerLoaded() {
      this.$nextTick(() => {
        this.$trackBannerEvent({
          ...this.mainBanner,
          action: 'BANNERIMPRESSION',
          url: this.mainBanner.click_url || '',
          order: 1,
          type: 'main-banner',
          impressionUrl: this.mainBanner.impression_url,
        });
  
        const { impression_url: impressionUrl, pixel_tracker_url: pixelTrackerUrl } = this.mainBanner;
        if (impressionUrl) {
          this.sendTracker(impressionUrl);
        }
        if (pixelTrackerUrl) {
          this.sendTracker(pixelTrackerUrl);
        }
      })
    },
    sendTracker(url) {
      window.axios.get(url);
    },
  }
}
</script>