<template>
  <div
    :id="$fragment"
    class="banner-mweb-lite__container"
  >
    <HomeBanner :main-banner="mainBanner" />
    <div class="banner-mweb-lite__action">
      <a
        href="/promo"
        data-test-id="ut-all-promo"
        class="c-btn c-btn--tiny banner-mweb-lite__promotion-button"
      >SEMUA PROMO</a>
    </div>
  </div>
</template>
<script>
import HomeBanner from './components/HomeBannerLite';

export default {
  components: {
    HomeBanner,
  },
  props: {
    mainBanner: {
      type: Object,
      default: () => ({}),
    }
  },
}
</script>