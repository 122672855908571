import TrackBannerEventPlugin from 'home-section/plugins/TrackBannerEvent';
import createApp from './createApp';
import { APP_ID, APP_INITIAL_STATE } from './constants';

const { Vue, USER, Tracker } = window;

Vue.use(TrackBannerEventPlugin, { USER, Tracker, ObserverTracker() {} });

const state = (global && global[APP_INITIAL_STATE]) || undefined;

const { app } = createApp({ Vue, state });

document.cookie = 'homepage-visited=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';

app.$mount(`#${APP_ID}`);
