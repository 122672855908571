import App from './App';
import { APP_ID } from './constants';

function createApp({ Vue, state }) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$fragment = APP_ID;

  const mainBanner = state.mainBanner || {};

  const app = new Vue({
    render: h =>
      h(App, {
        props: {
          mainBanner,
        },
      }),
  });

  return { app };
}

export default createApp;
